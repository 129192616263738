$(document).on('mousedown touchstart', function(e){
	var blur_off = $('.js-blurOff');
	if(!blur_off.is(e.target) && blur_off.has(e.target).length === 0){
		$('.js-nav').removeClass('opened');
		$('.js-aside').removeClass('opened');
		$('body').removeClass('nav-opened');
	}
	// if (!$(e.target).is('.collapse-trigger')) {
	// 	$('.header .collapse').collapse('hide');
	// }
});
