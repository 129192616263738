$(document).ready(function() {
	$('.js-ratingStars').length && $('.js-ratingStars').rating({
		hoverOnClear: false,
		theme: 'krajee-fa',
		showClear: false,
		showCaption: false,
		filledStar: '<i class="fa fa-star"></i>',
		emptyStar: '<i class="fa fa-star"></i>'
	});


	$('.js-slider4').length && $('.js-slider4').slick({
		slidesToShow: 4,
		slidesToScroll: 4,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4,
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				}
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			}
		]
	});
	$('.js-slider5').length && $('.js-slider5').slick({
		slidesToShow: 5,
		slidesToScroll: 5,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4,
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				}
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				}
			}
		]
	});
	$('.js-sliderVoucher').length && $('.js-sliderVoucher').slick({
		slidesToShow: 3,
		slidesToScroll: 3,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				}
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			}
		]
	});
	$('.js-sliderSingle').length && $('.js-sliderSingle').slick();
	if ($('.js-sliderSyncFor').length) {
		$('.js-sliderSyncFor').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			fade: true,
			asNavFor: '.js-sliderSyncNav',
		});
		$('.js-sliderSyncNav').slick({
			slidesToShow: 6,
			slidesToScroll: 1,
			asNavFor: '.js-sliderSyncFor',
			focusOnSelect: true,
			arrows: true,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 6
					}
				},
				{
					breakpoint: 576,
					settings: {
						slidesToShow: 4
					}
				}
			]
		});
	}

	// TOGGLE NAVIGATION
	$('.js-navOpen').on('click', function() {
		let body = $('body'),
				nav = $('.js-nav');

		body.toggleClass('nav-opened');
		nav.toggleClass('opened');
	});

	// PRODUCT ASIDE
	$('.js-asideTrigger').on('click', function() {
		$('body').toggleClass('nav-opened');
		$('.js-aside').toggleClass('opened');
		$('.aside-wrap').animate({scrollTop: 0}, 350)
	});

	// MAGNIFIC POPUP
	

	// TAB NAVIGATION
	$('.js-tabLink').on('click', function() {
		var target = $(this).data('target'),
			parent = $(this).closest('.js-tabParent');

		parent.find('.js-tabNav .js-tabLink').removeClass('active');
		parent.find('.js-tabContent .tab-panel').removeClass('active');
		$(target).addClass('active');
		$(this).addClass('active');
	})
	
	//SMOOTSCROLL
	$('.js-pageDirectionTrigger').on('click', function() {
		var elem = $(this),
			header = $('.header'),
			parent = $('.js-pageDirection');
		smoothScroll(elem,header);
		parent.find('.js-pageDirectionTrigger').removeClass('active');
		$(this).addClass('active');
	})
});
